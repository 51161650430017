<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import GlobalService from '@/services/global';
import WdmService from '@/services/wdm';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import Vue from 'vue';
import { mapGetters } from 'vuex';


var templateRenderFns = [];
export default {
  name: 'DocumentTreeList',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {
    WisdomDataModal,
    WisdomDataShow,
    Modal
  },
  props: {
    p_treeListData: {
      type: Object,
      required: true
    }
  },
  computed: mapGetters({
    lang: 'lang',
    help: 'help'
  }),
  data () {
    return {
      sliding: false,
      user: '',
      template: null,
      d_levelCount: 20, // this is defined as default value, to be in safe.
      d_startFromDragType: '',
      d_level: 0,
      d_icons: {
        '2930471': require('../../icon/2930471.png')
      },
      d_wisdomData: {
        'index_list': [],
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdmr231List: [],
      d_selectedWdmrData: {},
      d_otherRelationData: {
        'show': false,
        'item': { 'label': '', 'key': '' },
        'selected': '',
        'relation': '',
        'list': []
      },
      d_selectedFirstLayerIndex: '',
      d_cardTreeList: [],
      d_docUri: '',
      wisdomera_system: ''
    };
  },
  mounted () {},
  created: function () {
    this.wisdomera_system = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (this.wisdomera_system.system_type === 'developer') {
      this.d_docUri = 'http://localhost:8079/';
    } else {
      this.d_docUri = 'https://docs.wisdomera.io/';
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    this.render_template();
    this.f_wdmList();
    if (this.p_treeListData.template && this.p_treeListData.template.type === '1') {
      this.d_selectedFirstLayerIndex = '0';
      if (this.p_treeListData.list.length > 0) {
        this.f_getWdmrData(this.p_treeListData.list[0].key);
      }
      this.d_cardTreeList = JSON.parse(JSON.stringify(this.p_treeListData.list));
    } else {
      this.f_getWdmrData(this.p_treeListData.parent.key);
    }
  },
  methods: {
    render_template: function () {
      // this.d_level = 0; // When we call this function again, this.d_level must be 0.
      this.d_level = 0;
      this.f_calculateDeepLevel();
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
    },
    f_calculateDeepLevel: function () {
      let result = { 'item_index': [], 'max': 0 };
      this.f_calculateMaximumDeepLevelCount(this.p_treeListData.list, result);
      this.d_levelCount = result.max;
      // this.d_levelCount = this.p_treeListData.items.length;
      // console.log('this.d_levelCount: ', this.d_levelCount);
    },
    build_form: function () {
      let html = '';
      html += '<b-row v-if="user && user.permissions_result && user.permissions_result.indexOf(\'wisdom\') !== -1">';
      html += '   <b-col sm="12" md="12">';
      html += '      <b-button class="pull-right" variant="success" @click="f_changeTemplate()">template</button>';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<b-nav tabs pills v-if="p_treeListData.template && p_treeListData.template.type === \'1\'" style="background-color: black; padding: 15px;">';
      html += '   <template v-for="(first_layer, first_layer_ind) in p_treeListData.list">';
      html += '      <b-nav-item @click="f_selectThisFirstLayer(first_layer_ind.toString(), first_layer.key, first_layer.type)" :active="first_layer_ind.toString() === d_selectedFirstLayerIndex">';
      html += '         <strong style="color: #409382!important;">{{ first_layer.label }}</strong>';
      html += '      </b-nav-item>';
      html += '      <template v-if="first_layer.list.length > 0">';
      html += '         <template v-for="(first_sub_layer, first_sub_layer_ind) in first_layer.list">';
      html += '            <b-nav-item @click="f_selectThisFirstLayer(first_sub_layer.toString() + \'_\' + first_sub_layer_ind.toString(), first_sub_layer.key, first_sub_layer.type)" :active="first_sub_layer.toString() + \'_\' + first_sub_layer_ind.toString() === d_selectedFirstLayerIndex">';
      html += '               <strong style="color: #409382!important;">{{ first_sub_layer.label }}</strong>';
      html += '            </b-nav-item>';
      html += '         </template>';
      html += '      </template>';
      html += '   </template>';
      html += '</b-nav>';
      html += '<b-row v-if="p_treeListData.template && p_treeListData.template.type === \'2\'">';
      html += '   <b-col sm="12" md="3">';
      html += '      <template v-if="p_treeListData.parent_wdmr_data && p_treeListData.parent_wdmr_data.id">';
      html += '         <div style="padding: 5px; font-size: 14px;">';
      html += '            <strong style="cursor: pointer;" @click="f_getWdmrData(p_treeListData.parent.key)">';
      html += '               {{ p_treeListData.parent_wdmr_data.label }}';
      html += '            </strong>';
      html += '         </div>';
      html += '      </template>';
      html += '   </b-col>';
      html += '   <b-col sm="12" md="9">';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<b-row>';
      html += '   <b-col sm="12" md="12">';
      html += this.f_createWdmrHtml();
      html += '   </b-col>';
      html += '</b-row>';
      html += '<b-row v-if="user && user.permissions_result && user.permissions_result.indexOf(\'documentation-edit\') !== -1 && p_treeListData.template && p_treeListData.template.type === \'2\'">';
      html += '   <b-col sm="12" md="3">';
      html += '      <div @drop="f_onDropRule($event, [])" @dragover.prevent @dragenter.prevent style="width: 25px; height: 25px; border-radius: 25px 25px 25px 25px; cursor: pointer; background-color: #b6f7f6; margin-bottom: 3px; text-align: center;" @click="f_addNewChildWisdomData(p_treeListData.child.type, [], p_treeListData.parent.key, p_treeListData.parent.type)" class="pull-right"><span style="color: green;">+</span></div>';
      html += '   </b-col>';
      html += '   <b-col sm="12" md="9">';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<b-row v-if="p_treeListData.template && p_treeListData.template.type === \'1\'">';
      html += '   <b-col sm="12" md="12">';
      html += '      <b-row style="margin: 0px;">';
      this.d_level = 0;
      let card_tree_html = this.f_createCardHtmlOfTreeList();
      // console.log(card_tree_html);
      html += card_tree_html;
      html += '      </b-row>';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<b-row v-if="p_treeListData.template && p_treeListData.template.type === \'2\'">';
      html += '   <b-col v-if="p_treeListData.list.length > 0 || (p_treeListData.list.length === 0 && user && user.permissions_result && user.permissions_result.indexOf(\'documentation-edit\') !== -1)" sm="12" md="3" style="padding-right: 3px;">';
      let h = window.innerHeight - 170;
      let menu_style = ':style="\'height: ' + h + 'px; overflow-y: auto; overflow-x: hidden; background: \' + p_treeListData.style.menu.background + \'; padding: \' + p_treeListData.style.menu.padding + \'px; box-shadow: \' + p_treeListData.style.menu.boxShadow + \'; border: \' + p_treeListData.style.menu.border + \'px;\'"';
      html += '      <div id="leftmenufield" ' + menu_style + '>';
      this.d_level = 0;
      html += this.f_createTree();
      html += '      <div>';
      html += '   </b-col>';
      html += '   <b-col sm="12" md="9" style="padding-left: 3px;">';
      html += this.f_createWdmrHtmlRight();
      html += '   </b-col>';
      html += '</b-row>';
      html += this.f_createModalHtml();
      html += this.f_createOtherRelationModal();
      return '<div>' + html + '</div>';
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    f_createWdmrHtml: function () {
      let html = '';
      html += '<template v-if="d_selectedWdmrData && d_selectedWdmrData.id && p_treeListData.template && p_treeListData.template.type === \'1\'">';
      html += '   <b-card no-body bg-variant="dark">';
      html += '      <template v-if="d_selectedWdmrData.data.general.info && d_selectedWdmrData.data.general.info.list && d_selectedWdmrData.data.general.info.list.length > 0">';
      html += '         <b-carousel id="carousel1" style="text-shadow: 1px 1px 2px #333; height: 100%;" controls indicators background="#ababab" :interval="10000" v-model="sliding" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">';
      html += '            <template v-for="(info, info_ind) in d_selectedWdmrData.data.general.info.list">';
      html += '               <b-carousel-slide style="color: black; height: 300px;">';
      html += '                  <img v-if="info && info.image && info.image.val" slot="img" :src="info.image.val" class="d-block img-fluid w-100" style="max-height: 300px;" alt="image slot">';
      html += '                  <b-row v-if="info && info.explanation && info.explanation.val" style="margin: 5px; font-size: 16px;">';
      html += '                     <b-col sm="12" md="12">';
      html += '                        <div v-html="f_getInfoHtml(info.explanation.val)"></div>';
      html += '                     </b-col>';
      html += '                 </b-row>';
      html += '               </b-carousel-slide>';
      html += '            </template>';
      html += '         <b-carousel>';
      html += '      </template>';
      html += '   </b-card>';
      // html += '            <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedWdmrData" :data_type="d_selectedWdmrData.type" :option_data="d_wisdomData.option_data[d_selectedWdmrData.type]"></wisdom-data-show>';
      html += '</template>';
      return html;
    },
    f_createWdmrHtmlRight: function () {
      let html = '';
      html += '<template v-if="d_selectedWdmrData && d_selectedWdmrData.id && p_treeListData.template && p_treeListData.template.type === \'2\'">';
      html += '   <b-row style="margin: 5px;">';
      html += '      <b-col sm="12" md="12">';
      html += '         <strong style="font-size: 26px;"> {{ d_selectedWdmrData.label }} </strong>';
      html += '      </b-col>';
      html += '   </b-row>';
      html += '    <template v-if="d_selectedWdmrData.data && d_selectedWdmrData.data.general && d_selectedWdmrData.data.general.info && d_selectedWdmrData.data.general.info.list && d_selectedWdmrData.data.general.info.list.length > 0">';
      html += '       <template v-for="(info, info_ind) in d_selectedWdmrData.data.general.info.list">';
      html += '          <b-row v-if="info && info.explanation && info.explanation.val" style="margin: 5px; font-size: 16px;">';
      html += '             <b-col sm="12" md="12">';
      html += '                <div v-html="f_getInfoHtml(info.explanation.val)"></div>';
      html += '             </b-col>';
      html += '          </b-row>';
      html += '          <b-row v-if="info && info.image && info.image.val" style="margin: 5px;">';
      html += '             <b-col sm="12" md="12">';
      html += '                <img :src="info.image.val" style="max-width: 75%; border-radius: 25px">';
      html += '             </b-col>';
      html += '          </b-row>';
      html += '    </template>';
      html += '    </template>';
      // html += '            <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedWdmrData" :data_type="d_selectedWdmrData.type" :option_data="d_wisdomData.option_data[d_selectedWdmrData.type]"></wisdom-data-show>';
      html += '</template>';
      return html;
    },
    f_createModalHtml: function () {
      let html = '';
      html += '<modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="\'1000\'">';
      html += '<h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>';
      html += '<div slot="body">';
      html += '<wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>';
      html += '</div>';
      html += '<div slot="footer">';
      html += '<button type="button" class="btn btn-success" v-on:click="f_saveChildWdm()">{{ $t(\'wdm16.4061\') }}</button>';
      html += '<button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t(\'wdm16.4053\') }}</button>';
      html += '</div>';
      html += '</modal>';
      return html;
    },
    f_createOtherRelationModal: function () {
      let html = '';
      html += '<modal v-if="d_otherRelationData.show" @close="d_otherRelationData.show = false" :width="\'500\'">';
      html += '<h3 slot="header">{{ d_otherRelationData.item.label }} <small style="color: red;">({{ d_otherRelationData.item.key }})</small></h3>';
      html += '<div slot="body">';
      html += '   <b-form-select v-model="d_otherRelationData.selected">';
      html += '      <option v-for="(x, x_ind) in d_otherRelationData.list" :value="x">';
      html += '         {{ x.label }}';
      html += '      </option>';
      html += '   </b-form-select>';
      html += '</div>';
      html += '<div slot="footer">';
      html += '<button v-if="d_otherRelationData.selected" type="button" class="btn btn-success" v-on:click="f_applyItemRelation()">{{ $t(\'wdm16.4061\') }}</button>';
      html += '<button type="button" class="btn btn-danger" @click="d_otherRelationData.show = false">{{ $t(\'wdm16.4053\') }}</button>';
      html += '</div>';
      html += '</modal>';
      return html;
    },
    f_createTree: function () {
      let html = '';
      this.d_level += 1;
      let parent_index_list = '[' + this.f_parentItemIndexList() + ']';
      // console.log('parent_index_list', parent_index_list);
      let index_list = '[' + this.f_itemIndexList() + ']';
      html += '<template v-if="p_treeListData.template && p_treeListData.template.type === \'2\'">';
      html += '   <template v-for="(item' + this.d_level.toString() + ', item_index' + this.d_level.toString() + ') in ' + this.f_treeListData() + '">';
      // let item_style = ':style="\'background: \' + p_treeListData.style.item.background + \'; color: \' + p_treeListData.style.item.color + \'; box-shadow: \' + p_treeListData.style.item.boxShadow + \'; width: \'+ p_treeListData.style.item.width + \'px; margin: \'+ p_treeListData.style.item.margin + \'px; margin-left:' + (this.d_level * this.p_treeListData.style.item.left_plus).toString() + 'px;\'"';
      let item_style = ':style="f_calculateItemStyle(' + this.d_level + ')"';
      // console.log(item_style);
      let selection_style = 'p_treeListData.child.type + \'_\' + d_selectedWdmrData.id === item' + this.d_level.toString() + '.key ? \'background-color: #defbff;\' : \'\'';
      html += '   <div class="menuitem" :style="' + selection_style + '">';
      html += '   <div ' + item_style + ' @drop="f_onDropRule($event, ' + index_list + ')" @dragover.prevent @dragenter.prevent>';
      html += '      <b-row style="margin: 0px; min-height: 30px;">';
      html += '         <b-col sm="12" :md="user && user.permissions_result && user.permissions_result.indexOf(\'documentation-edit\') !== -1 ? 10 : 12" @click="f_getWdmrData(item' + this.d_level.toString() + '.key)">';
      html += '            {{ item' + this.d_level.toString() + '.label }} <small v-if="user && user.permissions_result && user.permissions_result.indexOf(\'documentation-edit\') !== -1">({{ item' + this.d_level.toString() + '.key }})</small>';
      html += '         </b-col>';
      html += '         <b-col v-if="user && user.permissions_result && user.permissions_result.indexOf(\'documentation-edit\') !== -1" sm="12" md="2">';
      html += '            <b-dropdown class="pull-right" variant="secondary" text="" size="sm" v-if="(\'wdm231_\' + d_selectedWdmrData.id) === item' + this.d_level.toString() + '.key">';
      html += '               <b-dropdown-item @click="f_addNewChildWisdomData(p_treeListData.child.type, ' + index_list + ', item' + this.d_level.toString() + '.key, item' + this.d_level.toString() + '.type)">yeni alt katman</b-dropdown-item>';
      html += '               <b-dropdown-item @click="f_editChildWdmr(p_treeListData.child.type, ' + parent_index_list + ', ' + index_list + ')">düzenle</b-dropdown-item>';
      html += '               <b-dropdown-item v-if="item' + this.d_level.toString() + '.list.length === 0" @click="f_deleteItem()">sil</b-dropdown-item>';
      html += '               <b-dropdown-item @click="f_createIframe(item' + this.d_level.toString() + '.key)">iframe oluştur</b-dropdown-item>';
      html += '               <b-dropdown-item v-for="(rel, rel_ind) in p_treeListData.child.other_possible_relations" @click="f_otherRelation(item' + this.d_level.toString() + ', rel)">{{ rel.label }}</b-dropdown-item>';
      html += '            </b-dropdown>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </div>';
      html += '   </div>';
      // html += '   <img v-if="item' + this.d_level.toString() + '.list.length !== 0" :src="d_icons[\'931299\']" style="width: ' + (4 / this.d_level) + 'em; margin-left:' + (this.d_level * 30) + 'px;" />';
      // html += '   <div v-if="item' + this.d_level.toString() + '.list.length === 0" style="height:' + (4 / this.d_level) + 'em;"></div>'
      if (this.d_level <= this.d_levelCount) {
        html += this.f_createTree();
        this.d_level -= 1;
      }
      html += '   </template>';
      html += '</template>';
      return html;
    },
    f_createCardHtmlOfTreeList: function () {
      let html = '';
      this.d_level += 1;
      let parent_index_list = '[' + this.f_cardTreeparentItemIndexList() + ']';
      // console.log('parent_index_list', parent_index_list);
      let index_list = '[' + this.f_cardTreeitemIndexList() + ']';
      let list_item = this.f_cardTreeListData();
      // console.log('list_item: ', list_item);
      html += '<template v-for="(item' + this.d_level.toString() + ', item_index' + this.d_level.toString() + ') in ' + list_item + '">';
      // html += '      <b-col v-if="f_controlItemLayer(' + index_list + ')" sm="12" lg="4">';
      html += '      <b-col sm="12" lg="4">';
      html += '         <b-card footer-class="px-3 py-2" style="height: 100%;">';
      html += '            <b-row style="margin: 5px;">';
      html += '               <b-col sm="12" md="12">';
      html += '                  <strong> {{ item' + this.d_level.toString() + '.label }} </strong>';
      html += '               </b-col>';
      html += '            </b-row>';
      html += '            <template v-if="item' + this.d_level.toString() + '.wdmr.data && item' + this.d_level.toString() + '.wdmr.data.general && item' + this.d_level.toString() + '.wdmr.data.general.info && item' + this.d_level.toString() + '.wdmr.data.general.info.list && item' + this.d_level.toString() + '.wdmr.data.general.info.list.length > 0">';
      html += '               <template v-for="(info, info_ind) in item' + this.d_level.toString() + '.wdmr.data.general.info.list">';
      html += '                  <b-row v-if="info && info.explanation && info.explanation.val" style="margin: 5px;">';
      html += '                     <b-col sm="12" md="12">';
      html += '                        <div v-html="f_getInfoHtmlCardTreeList(info.explanation.val)"></div>';
      html += '                     </b-col>';
      html += '                  </b-row>';
      html += '                  <b-row v-if="info && info.image && info.image.val" style="margin: 5px;">';
      html += '                     <b-col sm="12" md="12">';
      html += '                        <img :src="info.image.val" style="max-width: 75%;">';
      html += '                     </b-col>';
      html += '                  </b-row>';
      html += '               </template>';
      html += '            </template>';
      html += '            <div slot="footer">';
      html += '               <b-link class="font-weight-bold font-xs btn-block text-muted" :href="\'' + this.d_docUri + '#/documenttree?wdmr231=\' + item' + this.d_level.toString() + '.key.split(\'_\')[1]">Detaylar <i class="fa fa-angle-right float-right font-lg"></i></b-link>';
      html += '            </div>';
      html += '         </b-card>';
      html += '      </b-col>';
      if (this.d_level <= this.d_levelCount) {
        html += this.f_createCardHtmlOfTreeList();
        this.d_level -= 1;
      }
      html += '</template>';
      return html;
    },
    f_controlItemLayer: function (index_list) {
      if (index_list.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    f_selectThisFirstLayer: function (layer_ind, layer_key, layer_type) {
      this.f_getWdmrTreeList(layer_key, layer_type);
      this.d_selectedFirstLayerIndex = layer_ind;
      this.f_getWdmrData(layer_key);
      this.$forceUpdate();
    },
    f_getWdmrTreeList: function (wdmr_key, wdm_type) {
      let parent = { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
        'key': wdmr_key,
        'type': wdm_type,
        'bucket': 'wisdom'
      };
      let data = {
        'parent_wdm': parent,
        'child_wdm': this.p_treeListData.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'all' // first or all
      };
      WdmService.get_wdmr_tree_list(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_cardTreeList = resp.data.result.list;
            this.render_template();
          } else {
            console.log('errorr get_wdmr_tree_list : ', resp.data.message);
          }
        });
    },
    f_changeTemplate: function () {
      if (this.p_treeListData.template) {
        if (this.p_treeListData.template.type === '1') {
          this.p_treeListData.template.type = '2';
        } else if (this.p_treeListData.template.type === '2') {
          this.p_treeListData.template.type = '1';
        }
        this.$forceUpdate();
      }
    },
    f_applyItemRelation: function () {
      // Now we anly need to add child key to parent keys document. But we send also child wdmr.
      // Because we will maybe add some child wdmr's parameters to the parent keys document in the future.
      // console.log('this.d_wisdomData', this.d_wisdomData);
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      parent_wdm = {
        'label': '',
        'key': this.d_otherRelationData.selected.key,
        'bucket': this.d_otherRelationData.relation.parent.bucket,
        'type': this.d_otherRelationData.relation.parent.type
      };
      child_wdm = {
        'label': '',
        'key': this.p_treeListData.child.reference_wdm_key,
        'bucket': this.p_treeListData.child.bucket,
        'type': this.p_treeListData.child.type
      };
      relation_type = this.d_otherRelationData.relation.relation_type;
      let d_wisdomData = {
        'wisdom_op': 'edit',
        'data_type': this.p_treeListData.child.type,
        'wisdom_data': this.d_selectedWdmrData,
        'option_data': {}
      };
      d_wisdomData.option_data[this.p_treeListData.child.type] = this.d_wisdomData.option_data[this.p_treeListData.child.type];
      ClsWdmrManage.save_child_wdmr(d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          this.d_otherRelationData.show = false;
        }, resp => {
          console.log('error :', resp);
          alert('error ', resp);
        });
    },
    f_otherRelation: function (item, relation) {
      /*
        Any relation looks like:
        relation = {
            'label': 'Web sayfaları ile ilişkilendir',
            'relation_type': 'keys',
            'parent': { 'key': '', 'type': 'wdm233', 'bucket': 'wisdom', },
            'child': { 'reference_wdm_key': 'wdm_wisdom_wdm232_v2.0', 'type': 'wdm232', 'bucket': 'wisdom' }
        }
      */
      this.f_getChildWdmrList(relation.parent, relation.child, relation.relation_type);
      this.d_otherRelationData.show = true;
      this.d_otherRelationData.item = item;
      this.d_otherRelationData.relation = relation;
    },
    f_getChildWdmrList: function (parent, child, relation_type) {
      this.d_otherRelationData.list = [];
      ClsWdmrManage.get_related_wdmr_list(parent, child, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_otherRelationData.list = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    },
    f_createIframe: function (key) {
      alert('<iframe width="100%" height="500px" src="https://docs.wisdomera.io/#/showonedocument?docid=' + key.split('_')[1] + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
    },
    f_getInfoHtml: function (txt) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^\"#\&\?]*).*/;
      let match = txt.match(regExp);
      // console.log(match);
      let videoId = '';
      if (match && match.length > 2) {
        videoId = match[2];
      }
      // console.log('videoId', videoId);
      if (videoId) {
        let replace_text = '<figure class=\"media\"><oembed url=\"https://www.youtube.com/watch?v=' + videoId + '\"></oembed></figure>';
        let iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
        txt = txt.replace(replace_text, iframeMarkup);
      }
      // console.log(txt)
      return txt;
    },
    f_getInfoHtmlCardTreeList: function (txt) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^\"#\&\?]*).*/;
      let match = txt.match(regExp);
      // console.log(match);
      let videoId = '';
      if (match && match.length > 2) {
        videoId = match[2];
      }
      // console.log('videoId', videoId);
      if (videoId) {
        let replace_text = '<figure class=\"media\"><oembed url=\"https://www.youtube.com/watch?v=' + videoId + '\"></oembed></figure>';
        let iframeMarkup = '<iframe max-width="100%" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
        let starting_text = txt.substring(0, txt.indexOf(replace_text));
        if (starting_text.length > 300) {
          starting_text = starting_text.substring(0, 300) + '...';
        }
        return starting_text + iframeMarkup;
      } else {
        // console.log(txt)
        if (txt.length > 300) {
          return txt.substring(0, 300) + '...';
        } else {
          return txt;
        }
      }
    },
    f_calculateItemStyle: function (level) {
      let style = '';
      style = '\'padding: \' + p_treeListData.style.item.padding + \'px; border: \' + p_treeListData.style.item.border + \'; background: \' + p_treeListData.style.item.background + \'; color: \' + p_treeListData.style.item.color + \'; box-shadow: \' + p_treeListData.style.item.boxShadow + \'; width: %100; margin: \'+ p_treeListData.style.item.margin + \'px; margin-left:' + (level * this.p_treeListData.style.item.left_plus).toString() + 'px; cursor: pointer;\'';
      return style;
    },
    f_deleteItem: function (index_list, item) {
      let list = this.f_getTreeLocation(index_list, 'list');
      list.splice(index_list[index_list.length - 1], 1);
      for (let i in this.p_treeListData.items) {
        if (this.p_treeListData.items[i].value === item.value) {
          this.$delete(this.p_treeListData.items[i], 'is_added');
          break;
        }
      }
      this.p_treeListData.items
      this.$forceUpdate();
    },
    f_startDragRule: function (evt, item_index, type) {
      // console.log('item_index', item_index);
      if (!this.p_treeListData.items[item_index].is_added) {
        this.d_startFromDragType = type;
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('fromInd', item_index);
      }
    },
    f_onDropRule: function (evt, index_list = []) {
      // console.log('index_list: ', index_list);
      // console.log('this.d_startFromDragTypeDate ', this.d_startFromDragTypeDate);
      let fromInd = evt.dataTransfer.getData('fromInd');
      // console.log('fromInd', fromInd);
      if (fromInd !== undefined && this.p_treeListData.items[fromInd]) {
        this.p_treeListData.items[fromInd].is_added = 1;
        let item = { 'value': this.p_treeListData.items[fromInd].value, 'label': this.p_treeListData.items[fromInd].label };
        item.list = [];
        if (index_list.length === 0) {
          this.p_treeListData.list.push(item);
        } else {
          let item_data = this.f_getTreeLocation(index_list, 'item_data');
          item_data.list.push(item);
        }
        this.$forceUpdate();
        // this.render_template();
        // if (this.d_startFromDragTypeField === 'free_div') {}
        // this.f_controlCompileStrFirstError();
      }
    },
    f_getTreeLocation: function (item_index_list, type) {
      let listLoc = this.p_treeListData.list;
      for (let i in item_index_list) {
        if (parseInt(i) !== item_index_list.length - 1) {
          listLoc = listLoc[item_index_list[i]].list;
          // console.log('listLoc: ', listLoc);
        } else {
          if (type === 'item_data') {
            return listLoc[item_index_list[i]];
          } else if (type === 'list') {
            // console.log('listLoc:', listLoc);
            return listLoc;
          }
        }
      }
      if (type === 'list') {
        return listLoc;
      } else if (type === 'item_data') {
        return this.tree_list_data;
      }
    },
    f_calculateMaximumDeepLevelCount: function (list_data, result) {
      for (let i in list_data) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        // console.log(result.item_index);
        if (result.item_index.length > result.max) {
          result.max = result.item_index.length;
        }
        list_data[i].index = result.item_index.join(',');
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_calculateMaximumDeepLevelCount(list_data[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    f_itemIndexList: function () {
      // upper index list
      let str = '';
      for (let i = 1; i <= this.d_level; i++) {
        str += 'item_index' + i.toString();
        if (i !== this.d_level) {
          str += ', ';
        }
      }
      return str;
    },
    f_cardTreeitemIndexList: function () {
      // upper index list
      let str = '';
      for (let i = 1; i <= this.d_level; i++) {
        str += 'item_index' + i.toString();
        if (i !== this.d_level) {
          str += ', ';
        }
      }
      return str;
    },
    f_parentItemIndexList: function () {
      let str = '';
      if (this.d_level > 1) {
        for (let i = 1; i <= this.d_level - 1; i++) {
          str += 'item_index' + i.toString();
          if (i !== this.d_level - 1) {
            str += ', ';
          }
        }
      }
      return str;
    },
    f_cardTreeparentItemIndexList: function () {
      let str = '';
      if (this.d_level > 1) {
        for (let i = 1; i <= this.d_level - 1; i++) {
          str += 'item_index' + i.toString();
          if (i !== this.d_level - 1) {
            str += ', ';
          }
        }
      }
      return str;
    },
    f_treeListData: function () {
      if (this.d_level === 1) {
        return 'p_treeListData.list';
      } else {
        return 'item' + (this.d_level - 1).toString() + '.list';
      }
    },
    f_cardTreeListData: function () {
      if (this.d_level === 1) {
        return 'd_cardTreeList';
      } else {
        return 'item' + (this.d_level - 1).toString() + '.list';
      }
    },
    f_getWdmrData: function (wdmr_key) {
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedWdmrData = resp.data.result;
          } else {
            this.d_selectedWdmrData = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
          this.f_changeFunc();
        });
    },
    f_editChildWdmr: function (data_type, parent_index_list, index_list, parent_key, type) {
      // if we applying edit, we must get parent key. Then we use parent index list.
      if (parent_index_list.length === 0) {
        this.d_wisdomData.parent = {
          'key': this.p_treeListData.parent.key,
          'bucket': this.p_treeListData.parent.bucket,
          'type': this.p_treeListData.parent.type
        };
      } else {
        let item_data = this.f_getTreeLocation(parent_index_list, 'item_data');
        this.d_wisdomData.parent = {
          'key': item_data.key,
          'bucket': this.p_treeListData.child.bucket,
          'type': item_data.type
        };
      }
      this.d_wisdomData.parent_index_list = parent_index_list;
      this.d_wisdomData.index_list = index_list;
      this.d_wisdomData.wisdom_op = "edit";
      this.d_wisdomData.data_type = data_type;
      this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdmrData));
      this.d_wisdomData.show = true;
    },
    f_saveChildWdm: function () {
      // console.log('this.d_wisdomData', this.d_wisdomData);
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      parent_wdm = {
        'label': '',
        'key': this.d_wisdomData.parent.key,
        'bucket': this.d_wisdomData.parent.bucket,
        'type': this.d_wisdomData.parent.type
      };
      child_wdm = {
        'label': '',
        'key': this.p_treeListData.child.reference_wdm_key,
        'bucket': this.p_treeListData.child.bucket,
        'type': this.p_treeListData.child.type
      };
      relation_type = 'keys';
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          let item = {
            'type': resp.wdmr.type ? resp.wdmr.type : '',
            'label': resp.wdmr.label ? resp.wdmr.label : '',
            'key': this.p_treeListData.child.type + '_' + resp.wdmr.id
          };
          if (this.d_wisdomData.wisdom_op === 'new') {
            item.list = [];
            if (this.d_wisdomData.index_list.length === 0) {
              this.p_treeListData.list.push(item);
            } else {
              let item_data = this.f_getTreeLocation(this.d_wisdomData.index_list, 'item_data');
              // console.log('item_data', item_data);
              item_data.list.push(item);
            }
            this.render_template();
          } else if (this.d_wisdomData.wisdom_op === 'edit') {
            let item_data = this.f_getTreeLocation(this.d_wisdomData.index_list, 'item_data');
            item_data.label = item.label;
            item_data.key = item.key;
          }
          this.d_wisdomData.show = false;
          this.f_changeFunc();
        }, resp => {
          alert('error ', resp);
        });
    },
    f_changeFunc: function () {
      if (this.d_wisdomData.change === '0') {
        this.d_wisdomData.change = '1';
      } else {
        this.d_wisdomData.change = '0';
      }
      this.$forceUpdate();
    },
    f_addNewChildWisdomData: function (data_type, index_list, parent_key, type) {
      ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
      this.d_wisdomData.parent = {
        'key': parent_key,
        'bucket': 'wisdom',
        'type': type
      };
      this.d_wisdomData.index_list = index_list;
      this.d_wisdomData.wisdom_op = 'new';
      this.d_wisdomData.show = true;
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm231_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_getWdmr231List: function () {
      this.d_wdmr231List = [];
      let parent_wdm = {
        'label': '',
        'key': 'wdm230_' + this.d_wdmr230.id,
        'bucket': 'wisdom',
        'type': 'wdm230'
      };
      let child_wdm = {
        'label': '',
        'bucket': 'wisdom',
        'type': 'wdm231'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdmr231List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    }
  },
  watch: {
    'p_treeListData.list': function () {
      // this.render_template();
    }
  }
};

</script>

<style>
.menuitem:hover {
  background-color: #dddada;
}

#leftmenufield::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#leftmenufield::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#leftmenufield::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

